import React, { useState, createContext, useEffect } from 'react'
import { logout, onResponseError, getTargets, getLandings } from '../../api'

const facebookEvents = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'Schedule',
  'Search',
  'SubmitApplication',
  'ViewContent',
  'StartTrial',
  'Purchase',
  'Subscribe',
]

export const Context = createContext()
const AppContext = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [targets, setTargets] = useState([])
  const [landings, setLandings] = useState([])
  const [userAuthenticated, setUserAuthenticated] = useState(null)
  const [navigation, setNavigation] = useState(1)

  onResponseError({
    403: () => setUserAuthenticated(false),
  })

  const fetchUserData = async () => {
    try {
      setLoading(true)
      const { data: targetsData } = await getTargets()
      setTargets(targetsData.targets)

      const { data: landingsData } = await getLandings()
      setLandings(landingsData.landings)
      setUserAuthenticated(true)
    } catch (err) {
      // Handle error?
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const logoutUser = async () => {
    await logout()
    setUserAuthenticated(false)
  }

  return (
    <Context.Provider
      value={{
        facebookEvents,
        targets,
        landings,
        userAuthenticated,
        setUserAuthenticated,
        fetchUserData,
        logoutUser,
        navigation,
        setNavigation,
        loading,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default AppContext
