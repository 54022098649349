/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Select, Modal, Button, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Context } from '../../context/Context'

const { Option } = Select

const handleErrors = (payload, errors) => Object.keys(payload).map(key => ({
  value: payload[key],
  name: key,
  errors: errors[key],
}))

const LandingForm = ({ onHide, title, onSave, initialValues = {} }) => {
  const navigate = useNavigate()
  const formRef = useRef(null)
  const { facebookEvents, targets, fetchUserData } = useContext(Context)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const saveLanding = async () => {
    try {
      setLoading(true)
      const landing = await formRef.current.validateFields()
      const { data, status } = await onSave(landing)
      if (status === 422) {
        form.setFields(handleErrors(landing, data.errors))
      } else {
        onHide()
        fetchUserData()
        navigate('/landings')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title={title}
      onCancel={onHide}
      visible={true}
      loading={loading}
      onOk={saveLanding}
      okText="Save"
    >
      <Form ref={formRef} layout="vertical" autoComplete="off" form={form} initialValues={initialValues}>
        <Form.Item name="name" label="Name">
          <Input placeholder="Name your landing..." />
        </Form.Item>
        <Form.Item name="url" label="URL">
          <Input placeholder="https://" />
        </Form.Item>
        <Form.Item
          name="tracking_url"
          label="Tracking url"
          extra="Appsflyer onelink or appmetrica tracking url"
        >
          <Input placeholder="https://" />
        </Form.Item>
        <Form.Item name="target_id" label="Target" rules={[{ required: true }]}>
          <Select
            placeholder="Target"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              targets.map(target => (
                <Select.Option value={target.id}>{`${target.provider_pixel_id}`}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <h1 className="text-lg font-semibold mb-2">Event Mapping</h1>
        <Form.List name="events_mapping">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'from_event_name']}
                    fieldKey={[fieldKey, 'event']}
                  >
                    <Input placeholder="App event name" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'to_event_name']}
                    fieldKey={[fieldKey, 'events']}
                    rules={[{ required: true, message: 'Missing to event' }]}
                  >
                    <Select
                      placeholder="Facebook event name"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {facebookEvents.map(event => (
                        <Option value={event}>{event}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add event
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}

export default LandingForm
