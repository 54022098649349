import axios from 'axios'
import qs from 'qs'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  xsrfCookieName: 'rails_csrf_token',
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets', skipNulls: true })
  },
  validateStatus(status) {
    return status === 200 || status === 201 || status === 422 || status === 401
  },
})

export const onResponseError = (errorHandlers) => {
  instance.interceptors.response.use(null, (error) => {
    const { response } = error
    const { status } = response || {}

    const handler = errorHandlers[status]
    if (typeof handler === 'function') {
      handler(error.response)

      return null
    }

    return Promise.reject(error)
  })
}

export const login = user => instance.post('user/login', { user })

export const register = user => instance.post('user/register', { user })

export const logout = () => instance.delete('user/logout')

export const getTargets = () => instance.get('targets')

export const createTarget = target => instance.post('targets', { target })

export const updateTarget = (id, target) => instance.patch(`targets/${id}`, { target })

export const getLandings = () => instance.get('landings')

export const createLanding = landing => instance.post('landings', { landing })

export const updateLanding = (id, landing) => instance.patch(`landings/${id}`, { landing })

export const testLanding = landingId => instance.post(`landings/${landingId}/test`)

export const sendTestEvent = (targetId, testEvent) => (
  instance.post(`targets/${targetId}/test`, { test_event: testEvent })
)

export const getEvents = ({ page, filter }) => instance.get('events', { params: { page, filter } })
