import React from 'react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import {
  FileOutlined,
  CalendarOutlined,
  PushpinOutlined,
  FileTextOutlined,
} from '@ant-design/icons'

const { Sider } = Layout

const SiderMenu = ({ collapsed }) => (
  <Sider theme="light" collapsible trigger={null} collapsed={collapsed}>
    <div className="flex mt-4 items center justify-center mb-8">
      <Link to="/events" className={collapsed ? 'invisible' : ''}>
        <p>web2app by </p>
        <img alt=" AppBooster logo" src="/assets/Logotype.svg" />
      </Link>
    </div>
    <Menu
      className="flex flex-col space-y-8 "
      mode="inline"
    >
      <Menu.Item
        key="events"
        icon={<CalendarOutlined />}
      >
        <Link to="/events">Events</Link>
      </Menu.Item>

      <Menu.Item
        key="targets"
        icon={<PushpinOutlined />}
      >
        <Link to="/targets">Targets</Link>
      </Menu.Item>

      <Menu.Item
        key="landings"
        icon={<FileOutlined />}
      >
        <Link to="/landings">Landings</Link>
      </Menu.Item>
      <Menu.Item
        key="docs"
        icon={<FileTextOutlined />}
      >
        <a href="https://appbooster.notion.site/Web2App-self-service-7ab079bfb4ff40208baa2e2cc23eaf8d" target="_blank">Docs</a>
      </Menu.Item>
    </Menu>
  </Sider>
)

export default SiderMenu
