import React, { useState, useContext } from 'react'
import { Layout, Menu, Avatar, Dropdown } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from '@ant-design/icons'
import SiderMenu from './SiderMenu'
import './sider.css'

import { Context } from '../context/Context'

const { Header } = Layout

const MenuLayout = ({ children }) => {
  const { logoutUser } = useContext(Context)
  const [collapsed, setCollapsed] = useState(false)
  const menu = (
    <Menu>
      <Menu.Item>
        <button onClick={logoutUser}>Logout</button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SiderMenu collapsed={collapsed} setCollapsed={setCollapsed} />

      <Layout className="site-layout">
        <Header className="site-layout-background " style={{ padding: 0 }}>
          <div className="flex justify-between items-center px-4">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
              },
            )}
            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <Avatar size="large" icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>
        {children}
      </Layout>
    </Layout>
  )
}

export default MenuLayout
