/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useCallback } from 'react'
import { Layout, Breadcrumb, Table, Space, PageHeader, Tag } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PopupEvent from './PopupEvent'
import Filter from './Filter'
import MenuLayout from '../../menu/MenuLayout'

import { getEvents } from '../../../api'

const formatTime = time => moment(time).format('YYYY/MM/DD HH:mm:ss')

const { Content } = Layout

const statusColors = {
  pending: 'geekblue',
  error: 'volcano',
  sent: 'green',
}

const Events = () => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [currentFilter, setCurrentFilter] = useState()
  const [eventDetails, setEventDetails] = useState(null)

  const fetchEvents = async ({ page = 1, filter = {} }) => {
    try {
      setLoading(true)
      const { data } = await getEvents({ page, filter })
      setEvents(data.events)
      setPagination(data.pagination)
      setCurrentPage(data.pagination.page)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEvents({ page: 1 })
  }, [])

  const onFilter = useCallback((updatedFilter) => {
    setCurrentFilter(updatedFilter)
    setCurrentPage(1)
    fetchEvents({ page: currentPage, filter: updatedFilter })
  }, [currentPage])

  const columns = [
    {
      title: 'Status',
      key: 'key',
      render: record => (
          <Tag color={statusColors[record.status]}>
            {record.status.toUpperCase()}
          </Tag>
      ),
    },
    {
      title: 'Landing',
      key: 'key',
      render: record => <Link to={`/landings/${record.landing.id}`}>{record.landing.name || record.landing.url}</Link>,
    },
    {
      title: 'Event name',
      dataIndex: 'event_name',
      key: 'key',
    },
    {
      title: 'Target',
      key: 'key',
      render: record => <Link to={`/targets/${record.target.id}`}>{record.target.provider_pixel_id}</Link>,
    },
    {
      title: 'Target event name',
      dataIndex: 'target_event_name',
      key: 'key',
    },
    {
      title: 'Receive at',
      dataIndex: 'received_at',
      key: 'key',
      render: formatTime,
    },
    {
      title: 'Sent at',
      dataIndex: 'sent_at',
      key: 'key',
      render: formatTime,
    },

    {
      title: 'Actions',
      key: 'key',
      render: record => (
          <Space size="middle">
            <button onClick={() => setEventDetails(record.source)} className="text-blue-400">
              details
            </button>
          </Space>
      ),
    },
  ]

  const paginationOptions = pagination.per_page
    ? {
      showSizeChanger: false,
      pageSize: pagination.per_page,
      current: pagination.page,
      total: pagination.total_items,
      onChange: page => fetchEvents({ page, filter: currentFilter }, pagination),
      hideOnSinglePage: true,
    }
    : false

  return (
    <MenuLayout>
      <Content className="px-4">
        <Breadcrumb
          className=" flex justify-end items-center pr-2"
          style={{ margin: '16px 0' }}
        >
          <Breadcrumb.Item direction="vertical" size={12}></Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-card-wrapper">
          <div className="flex justify-between items-center">
            <PageHeader className="site-page-header" title="Events" />
          </div>
          <div className="site-layout-background p-4">
            <Filter onFilter={onFilter} />

            {eventDetails && (
              <PopupEvent
                onClose={() => setEventDetails(null)}
                eventDetails={eventDetails}
              />
            )}
            <Table
              columns={columns}
              dataSource={events}
              loading={loading}
              pagination={paginationOptions}
              bordered
              simple
              scroll={{ x: 1200 }}
            />
          </div>
        </div>
      </Content>
    </MenuLayout>
  )
}

export default Events
