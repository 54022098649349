import React from 'react'
import { BrowserRouter as AllRouter, Route, Routes } from 'react-router-dom'
import { Layout } from 'antd'
import Targets from './components/pages/targets'
import Events from './components/pages/events'
import Landings from './components/pages/landings'
import Login from './components/Login'
import PageNotFound from './components/pages/404/PageNotFound'
import ProtectedRoutes from './components/pages/protected/ProtectedRoutes'

const { Footer } = Layout

function App() {
  return (
    <AllRouter>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/events" element={<Events />} />
          <Route path="/targets" element={<Targets />} />
          <Route path="/landings" element={<Landings />} />
          <Route path="/*" element={<PageNotFound />} />
        </Route>

        <Route path="/" element={<Login />} />
      </Routes>
      <Footer style={{ textAlign: 'center' }}>
        ©2021 Created by <a href="https://appbooster.com">AppBooster</a>
      </Footer>
    </AllRouter>
  )
}
export default App
