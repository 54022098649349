import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router'
import { Context } from '../../context/Context'

const ProtectedRoutes = () => {
  const { userAuthenticated } = useContext(Context)

  return userAuthenticated ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoutes
