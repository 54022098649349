import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import './index.css'
import App from './App'
import AppContext from './components/context/Context'

if (process.env.SENTRY_DSN) {
  Sentry.init({ dsn: process.env.SENTRY_DSN })
}

ReactDOM.render(
  <AppContext>
    <App />
  </AppContext>,
  document.getElementById('root'),
)
