/* eslint-disable react/jsx-no-bind */
import React, { useContext, useRef, useState, useCallback } from 'react'
import { Form, Input, Modal, Select } from 'antd'
import JSONPretty from 'react-json-pretty'

import { Context } from '../../context/Context'


const eventsWithValue = ['Purchase', 'StartTrial', 'Subscribe']

const TestTargetForm = ({ onHide, onTest }) => {
  const { facebookEvents } = useContext(Context)
  const [eventRequiresValue, setEventRequiresValue] = useState(false)
  const [facebookResponse, setFacebookResponse] = useState(false)
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const testTarget = async () => {
    try {
      setLoading(true)
      setFacebookResponse(null)
      const testEvent = await formRef.current.validateFields()
      const result = await onTest(testEvent)
      setFacebookResponse(result.data.target_response)
    } finally {
      setLoading(false)
    }
  }

  const onValuesChange = useCallback((changedValues, allValues) => {
    setEventRequiresValue(eventsWithValue.includes((allValues.event_name)))
  }, [setEventRequiresValue])

  return (
    <Modal
      title={'Test target'}
      onCancel={onHide}
      visible={true}
      confirmLoading={loading}
      onOk={testTarget}
    >
      <Form autoComplete="off" form={form} layout="vertical" ref={formRef} onValuesChange={onValuesChange}>
        <Form.Item label="Event name" name="event_name" rules={[{ required: true }]}>
          <Select
            placeholder="Facebook event name..."
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {
              facebookEvents.map(event => (
                <Select.Option value={event} key={event}>{`${event}`}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="test_event_code"
          label="Test event code"
          className="ml-1"
          extra={<p>Find our how to test facebook events in their <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjNpqjckon1AhVnxIsKHTNIBV8QFnoECAsQAQ&url=https%3A%2F%2Fwww.facebook.com%2Fbusiness%2Fhelp%2F1624255387706033&usg=AOvVaw2tYt32EDFWJT4D7_6fKPGh">documentation</a></p>}
        >
          <Input placeholder="TEST61027" />
        </Form.Item>
        {
          eventRequiresValue
            ? <>
                <Form.Item name="value" label="Value" className="ml-1">
                  <Input placeholder="5" />
                </Form.Item>

                <Form.Item name="currency" label="Currency" className="ml-1">
                  <Input placeholder="USD" />
                </Form.Item>
                <Form.Item name="predicted_ltv" label="Predicted LTV" className="ml-1">
                  <Input placeholder="5" />
                </Form.Item>
            </>
            : null
        }
        {
          facebookResponse
            ? <JSONPretty data={facebookResponse} />
            : null
        }
      </Form>
    </Modal>
  )
}

export default TestTargetForm
