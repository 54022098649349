/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { Modal, Steps, Button, Spin } from 'antd'

const { Step } = Steps

const TestLandingForm = ({ onHide, result }) => (
  <Modal
    title={'Test landing'}
    onCancel={onHide}
    visible={true}
    onOk={onHide}
    footer={[
      <Button key="back" onClick={onHide}>
        Ok
      </Button>,
    ]}
  >
    {
      result
        ? <Steps direction="vertical">
            <Step
              title="Configure facebook pixel"
              description="Add pixel JS code to your landing page"
              status={result.facebook_pixel_initialized_with_correct_id ? 'finish' : 'wait'}
            />
            <Step
              title="Add w2a.js"
              description="Add https://api.web2app.pro/w2a.js to your landing page"
              status={result.w2a_js_code_included ? 'finish' : 'wait'}
            />
            <Step
              title="Initialize w2a"
              description="Call w2a.addAppLinkClickHandler on your page"
              status={result.web2app_initialized ? 'finish' : 'wait'}
            />
          </Steps>
        : <div className="loading-wrapper">
            <Spin />
          </div>
    }
  </Modal>
)

export default TestLandingForm
