/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Table, Space, Layout, Breadcrumb, Button, PageHeader } from 'antd'
import { Context } from '../../context/Context'
import styles from '../List.less'
import MenuLayout from '../../menu/MenuLayout'
import TestLandingForm from './TestLandingForm'
import LandingForm from './LandingForm'
import EventsMappingPopup from './EventsMappingPopup'

import { createLanding, updateLanding, testLanding } from '../../../api'

const { Content } = Layout

const Landing = () => {
  const { landings, loading } = useContext(Context)
  const [landingToEdit, setLandingToEdit] = useState(null)
  const [testResult, setTestResult] = useState(null)
  const [eventsMapping, setEventsMapping] = useState(null)
  const [landingToTest, setLandingToTest] = useState(null)

  const onSave = useCallback(landing => (
    landingToEdit.id
      ? updateLanding(landingToEdit.id, landing)
      : createLanding(landing)
  ), [landingToEdit])

  const editLanding = useCallback((landing) => {
    setLandingToEdit({
      ...landing,
      target_id: landing.target.id,
    })
  }, [setLandingToEdit])

  const showLandingTestResult = useCallback(async (landing) => {
    setLandingToTest(landing)
    const result = await testLanding(landing.id)
    setTestResult(result.data.test)
  }, [setTestResult, setLandingToTest])

  const onHideTestLandingPopup = useCallback(() => {
    setTestResult(null)
    setLandingToTest(null)
  }, [setTestResult, setLandingToTest])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'key',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'key',
    },
    {
      title: 'Tracking URL',
      dataIndex: 'tracking_url',
      key: 'key',
    },
    {
      title: 'Target',
      key: 'key',
      render: record => <Link to={`/targets/${record.target.id}`}>{record.target.provider_pixel_id}</Link>,
    },
    {
      title: 'Events mapping',
      key: 'key',
      render: record => (
        <Space size="middle">
          <button onClick={() => setEventsMapping(record.events_mapping)} className="text-blue-400">
            Show
          </button>
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: record => (
        <Space size="middle">
          <button onClick={() => editLanding(record)}>Edit</button>
          <button onClick={() => showLandingTestResult(record)}>Test</button>
        </Space>
      ),
    },
  ]

  return (
    <MenuLayout>
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb
          className=" flex justify-end items-center pr-2"
          style={{ margin: '16px 0' }}
        >
          <Breadcrumb.Item direction="vertical" size={12}></Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-card-wrapper">
          <div className="flex justify-between items-center">
            <PageHeader className="site-page-header" title="Landings" />
            <Button
              onClick={() => setLandingToEdit({})}
              type="primary"
              htmlType="submit"
            >
              Create
            </Button>
          </div>
          <div className="site-layout-background p-4">
            {
              landingToTest
                ? <TestLandingForm
                    onHide={onHideTestLandingPopup}
                    result={testResult}
                  />
                : null
            }
            {
              landingToEdit
                ? <LandingForm
                    onHide={() => setLandingToEdit(null)}
                    title={landingToEdit.id ? 'Edit landing' : 'New landing'}
                    initialValues={landingToEdit}
                    onSave={onSave}
                  />
                : null
            }
            {
              eventsMapping
                ? <EventsMappingPopup
                    onHide={() => setEventsMapping(null)}
                    eventsMapping={eventsMapping}
                  />
                : null
            }
            <Table
              loading={loading}
              columns={columns}
              dataSource={landings}
              bordered
              scroll={{ x: 1200 }}
              className={styles.table}
              simple
              rowKey={record => record.id}
              pagination={false}
            />
          </div>
        </div>
      </Content>
    </MenuLayout>
  )
}

export default Landing
