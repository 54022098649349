import React from 'react'
import { Modal } from 'antd'
import JSONPretty from 'react-json-pretty'

const PopupEvent = ({ eventDetails, onClose }) => (
  <>
      <Modal
        title="Details"
        centered
        visible={true}
        onOk={onClose}
        onCancel={onClose}
        footer={null}
        width={1000}
      >
        <JSONPretty data={eventDetails} />
      </Modal>
  </>
)
export default PopupEvent
