/* eslint-disable react/jsx-no-bind */
import React, { useContext, useRef } from 'react'
import moment from 'moment'
import { Select, Row, Col, DatePicker, Form, Input, Button } from 'antd'
import { Context } from '../../context/Context'

const { RangePicker } = DatePicker

const ColProps = {
  xs: 24,
  sm: 12,
  style: {
    marginBottom: 16,
  },
}

const TwoColProps = {
  ...ColProps,
  xl: 96,
}

const Filter = ({ onFilter }) => {
  const { landings, targets, facebookEvents } = useContext(Context)
  const [form] = Form.useForm()
  const formRef = useRef(null)

  const statuses = {
    '': 'All',
    sent: 'Sent',
    skipped: 'Skipped',
    error: 'Error',
  }

  const onUpdateFilter = (filterValues) => {
    const filter = Object.keys(filterValues).reduce((acc, key) => {
      if (key === 'dates' && filterValues.dates) {
        acc.time_from = filterValues.dates[0].format()
        acc.time_to = filterValues.dates[0].format()

        return acc
      }
      if (filterValues[key]) {
        acc[key] = filterValues[key]
      }

      return acc
    }, {})
    onFilter(filter)
  }

  return (
    <div>
      <Form
        ref={formRef}
        form={form}
        layout="vertical"
        name="control-ref"
        onFinish={onUpdateFilter}
      >
        <Row gutter={24}>
          <Col {...ColProps} xl={{ span: 3 }} md={{ span: 6 }} id="status">
            <Form.Item label="Status" name="status">
              <Select placeholder="All" allowClear>
                {Object.keys(statuses).map(statusKey => (
                  <Select.Option value={statusKey} key={statusKey}>{statuses[statusKey]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...ColProps} xl={{ span: 3 }} md={{ span: 6 }} id="Landing">
            <Form.Item label="Landing" name="landing_id">
              <Select
                placeholder="Landing"
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {landings.map(landing => (
                  <Select.Option value={landing.id} key={landing.id}>{`${landing.name || landing.url}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col {...ColProps} xl={{ span: 3 }} md={{ span: 6 }}>
            <Form.Item label="Event" name="event_name">
              <Input placeholder={'Event name'} allowClear />
            </Form.Item>
          </Col>

          <Col {...ColProps} xl={{ span: 3 }} md={{ span: 6 }}>
            <Form.Item label="Target event" name="target_event_name">
            <Select placeholder="Target" allowClear>
                {
                  facebookEvents.map(event => (
                    <Select.Option value={event} key={event}>{`${event}`}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

          <Col {...ColProps} xl={{ span: 3 }} md={{ span: 6 }}>
            <Form.Item label="Target" name="target_id">
              <Select
                placeholder="Target"
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  targets.map(target => (
                    <Select.Option value={target.id} key={target.id}>{`${target.provider_pixel_id}`}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

          <Col
            {...ColProps}
            xl={{ span: 4 }}
            md={{ span: 6 }}
            sm={{ span: 12 }}
            id="createTimeRangePicker"
          >
            <div>
              <Form.Item label={'From / to'} name="dates">
                <RangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  showTime
                  format="YYYY/MM/DD HH:mm:ss"
                />
              </Form.Item>
            </div>
          </Col>
          <Col
            className="flex  justify-start items-end "
            {...TwoColProps}
            xl={{ span: 3 }}
            md={{ span: 6 }}
            sm={{ span: 24 }}
          >
            <Row type="flex" align="middle" justify="space-between">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="margin-right"
                >
                  Search
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Filter
