/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { Modal, Table, Empty } from 'antd'
import styles from '../List.less'

const EventsMappingPopup = ({ eventsMapping, onHide }) => {
  const columns = [
    {
      title: 'App event',
      dataIndex: 'from_event_name',
    },
    {
      title: 'Facebook event',
      dataIndex: 'to_event_name',
    },
  ]

  return (
    <Modal
      title="Events mapping"
      centered
      visible={true}
      onOk={onHide}
      onCancel={onHide}
      footer={null}
      width={1000}
    >
      {
        eventsMapping.length === 0
          ? <Empty />
          : <Table
              columns={columns}
              dataSource={eventsMapping}
              bordered
              scroll={{ x: 1200 }}
              className={styles.table}
              simple
              rowKey={record => record.id}
              pagination={false}
            />
      }
    </Modal>
  )
}

export default EventsMappingPopup
