/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState, useCallback } from 'react'
import {
  Table,
  Tag,
  Space,
  Layout,
  Breadcrumb,
  Button,
  PageHeader,
} from 'antd'
import styles from '../List.less'
import TargetForm from './TargetForm'
import TestTargetForm from './TestTargetForm'
import MenuLayout from '../../menu/MenuLayout'
import { Context } from '../../context/Context'

import { createTarget, updateTarget, sendTestEvent } from '../../../api'


const statusColors = {
  error: 'volcano',
  valid: 'green',
  pending: 'geekblue',
}

const { Content } = Layout

const Targets = () => {
  const { targets, loading } = useContext(Context)
  const [targetToEdit, setTargetToEdit] = useState(null)
  const [targetToTest, setTargetToTest] = useState(null)

  const onSave = useCallback(target => (
    targetToEdit.id
      ? updateTarget(targetToEdit.id, target)
      : createTarget(target)
  ), [targetToEdit])

  const editTarget = useCallback((target) => {
    setTargetToEdit(target)
  }, [setTargetToEdit])

  const testTarget = useCallback((target) => {
    setTargetToTest(target)
  }, [setTargetToTest])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'key',
    },
    {
      title: 'Pixel',
      dataIndex: 'provider_pixel_id',
      key: 'key',
    },
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'key',
      render: token => (
        token ? `${token.substring(0, 7)}...${token.substring(token.length - 7, token.length)}` : '-'
      ),
    },
    {
      title: 'Status',
      key: 'key',
      dataIndex: 'status',
      render: status => (
          <Tag color={statusColors[status]}>
            {status.toUpperCase()}
          </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      render: record => (
          <Space size="middle">
            <button onClick={() => editTarget(record)}>Edit</button>
            <button onClick={() => testTarget(record)}>Test</button>
          </Space>
      ),
    },
  ]

  return (
    <MenuLayout>
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb
          className=" flex justify-end items-center pr-2"
          style={{ margin: '16px 0' }}
        >
          <Breadcrumb.Item direction="vertical" size={12}></Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-card-wrapper">
          <div className="flex justify-between items-center">
            <PageHeader className="site-page-header" title="Targets" />
            <Button onClick={() => setTargetToEdit({})} type="primary" htmlType="submit">
              Create
            </Button>
          </div>
          <div className="site-layout-background p-4">
          {
              targetToTest
                ? <TestTargetForm
                    onHide={() => setTargetToTest(null)}
                    onTest={testEvent => sendTestEvent(targetToTest.id, testEvent)}
                  />
                : null
            }
            {
              targetToEdit
                ? <TargetForm
                    onHide={() => setTargetToEdit(null)}
                    title={targetToEdit.id ? 'Edit target' : 'New target'}
                    initialValues={targetToEdit}
                    onSave={onSave}
                  />
                : null
            }
            <Table
              loading={loading}
              columns={columns}
              dataSource={targets}
              bordered
              scroll={{ x: 1200 }}
              className={styles.table}
              simple
              rowKey={record => record.id}
              pagination={false}
            />
          </div>
        </div>
      </Content>
    </MenuLayout>
  )
}

export default Targets
