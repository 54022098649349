/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext, useEffect } from 'react'
import { Form, Button, Input, Row, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { login, register } from '../api'
import { Context } from './context/Context'

const handleValidationErrors = (payload, errors) => Object.keys(payload).map(key => ({
  value: payload[key],
  name: key,
  errors: errors[key],
}))

const handleAuthError = (payload, error) => Object.keys(payload).map(key => ({
  value: payload[key],
  name: key,
  errors: key === 'email' ? [error] : [],
}))

const SignUp = ({ children }) => (
  <>
      <Form.Item name="first_name" rules={[{ required: true }]} hasFeedback>
        <Input type="text" className="rounded-md" placeholder={'First name'} />
      </Form.Item>
      <Form.Item name="last_name" rules={[{ required: true }]} hasFeedback>
        <Input type="text" className="rounded-md" placeholder={'Last name'} />
      </Form.Item>
      <Form.Item name="email" rules={[{ required: true }]} hasFeedback>
        <Input type="email" className="rounded-md" placeholder={'email'} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true }]} hasFeedback>
        <Input type="password" className="rounded-md" placeholder={'Password'} />
      </Form.Item>
      <Row>
        <Button className="w-full rounded-md" type="primary" htmlType="submit">
          Register
        </Button>
        {children}
      </Row>
  </>
)

const SignIn = ({ children }) => (
  <>
      <Form.Item name="email" rules={[{ required: true }]} hasFeedback>
        <Input type="email" className="rounded-md" placeholder={'email'} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true }]} hasFeedback>
        <Input
          type="password"
          className="rounded-md"
          placeholder={'Password'}
        />
      </Form.Item>
      <Row>
        <Button className="w-full rounded-md" type="primary" htmlType="submit">
          Login
        </Button>
        {children}
      </Row>
  </>
)

const Login = () => {
  const [form] = Form.useForm()

  const { userAuthenticated, setUserAuthenticated, fetchUserData, loading } = useContext(Context)
  const [switcher, setSwitcher] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (userAuthenticated) {
      return navigate('/events')
    }

    return navigate('/')
  }, [userAuthenticated, navigate])

  const loginUser = async (values) => {
    const { data, status } = await login(values)
    if (status === 422) {
      form.setFields(handleValidationErrors(values, data.errors))
    } else if (status === 401) {
      form.setFields(handleAuthError(values, data.errors))
    } else {
      fetchUserData()
      setUserAuthenticated(true)
      navigate('/events')
    }
  }

  const registerUser = async (values) => {
    const { data, status } = await register(values)
    if (status === 422) {
      form.setFields(handleValidationErrors(values, data.errors))
    } else if (status === 401) {
      form.setFields(handleAuthError(values, data.errors))
    } else {
      fetchUserData()
      setUserAuthenticated(true)
      navigate('/events')
    }
  }

  const onFinish = async (values) => {
    // TODO: nooo
    if (!values.first_name && !values.last_name) {
      loginUser(values)
    } else if (values.first_name && values.last_name) {
      registerUser(values)
    }
  }

  const toggle = (
    <div className="flex w-full text-gray-400 px-2 mt-2 items-center justify-between">
      <button onClick={() => setSwitcher(!switcher)} className="margin-right">
        {switcher ? 'Register' : 'Login'}
      </button>
    </div>
  )

  if (loading) {
    return (
      <Spin size="large" style={{ paddingTop: '25%', paddingBottom: '25%', width: '100vw' }} />
    )
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-100">
      <div className="w-auto px-8 py-14 rounded-lg mx-auto flex flex-col h-auto items-center justify-center bg-white">
        <div className="flex flex-col space-y-4 items-center justify-center">
          <div className="">
            <p className="text-center">web2app by</p>
            <img alt="logo" src="/assets/Logotype.svg" />
          </div>
          <Form onFinish={onFinish} form={form} autoComplete="off" className={'w-64'}>
            {switcher ? <SignIn>{toggle}</SignIn> : <SignUp>{toggle}</SignUp>}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
