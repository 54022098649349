/* eslint-disable react/jsx-no-bind */
import React, { useRef, useState, useContext } from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../context/Context'

const handleErrors = (payload, errors) => Object.keys(payload).map(key => ({
  value: payload[key],
  name: key,
  errors: errors[key],
}))

const providers = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Tiktok', value: 'tiktok', disabled: true, title: 'Will be available soon' },
]

const TargetForm = ({ onHide, title, onSave, initialValues = {} }) => {
  const { fetchUserData } = useContext(Context)
  const [form] = Form.useForm()

  const navigate = useNavigate()
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const saveTarget = async () => {
    try {
      setLoading(true)
      const target = await formRef.current.validateFields()
      const { data, status } = await onSave(target)
      if (status === 422) {
        form.setFields(handleErrors(target, data.errors))
      } else {
        onHide()
        fetchUserData()
        navigate('/targets')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title={title}
      onCancel={onHide}
      visible={true}
      confirmLoading={loading}
      onOk={saveTarget}
    >
      <Form autoComplete="off" form={form} layout="vertical" ref={formRef} initialValues={initialValues}>
        <Form.Item name="provider" label="Provider" rules={[{ required: true }]}>
          <Select className="ml-1" options={providers} />
        </Form.Item>
        <Form.Item
          name="provider_pixel_id"
          label="Facebook Pixel Id"
          className="ml-1"
          rules={[{ required: true }]}
        >
          <Input placeholder="123123123123123" />
        </Form.Item>

        <Form.Item
          name="token"
          label="Conversion API Token"
          className="ml-1"
          extra={<p>Generate an <a href="https://developers.facebook.com/docs/marketing-api/conversions-api/get-started/">Access Token</a> in Events Manager</p>}
          rules={[{ required: true }]}
        >
          <Input placeholder="EAA...bZA" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default TargetForm
